import React, { useEffect, useState } from "react";
import SectionHeading from "../SectionHeading";
import "./clients-section.scss";
import client1 from "../../assets/images/client-1.png"
import client2 from "../../assets/images/client-2.png"
import client3 from "../../assets/images/client-3.png"
import client4 from "../../assets/images/client-4.png"
import client5 from "../../assets/images/client-5.png"
import client6 from "../../assets/images/client-6.png"

const Clients = () => {
  return (
    <div className="clients-section">
      <SectionHeading preText='🏢 Some Of Our Prestigious ' boldText='Clients' hideTextClients posText=' Who Have Grown With Us' style={{justifyContent: 'center'}} />
      <div className="clients-slider">
        <div className="clients-container">
          <div className="client-separator"></div>
          <img className="client-logo" src={client1} />
          <div className="client-separator"></div>
          <img className="client-logo" src={client2} />
          <div className="client-separator"></div>
          <img className="client-logo" src={client3} />
          <div className="client-separator"></div>
          <img className="client-logo" src={client4} />
          <div className="client-separator"></div>
          <img className="client-logo" src={client5} />
          <div className="client-separator"></div>
          <img className="client-logo" src={client6} />
          <div className="client-separator"></div>
          <img className="client-logo" src={client1} />
          <div className="client-separator"></div>
          <img className="client-logo" src={client2} />
          <div className="client-separator"></div>
          <img className="client-logo" src={client3} />
          <div className="client-separator"></div>
          <img className="client-logo" src={client4} />
          <div className="client-separator"></div>
          <img className="client-logo" src={client5} />
          <div className="client-separator"></div>
          <img className="client-logo" src={client6} />
          <div className="client-separator"></div>
          <img className="client-logo extra" src={client1} />
          <div className="client-separator"></div>
          <img className="client-logo  extra" src={client2} />
          <div className="client-separator  extra"></div>
          <img className="client-logo  extra" src={client3} />
          <div className="client-separator  extra"></div>
          <img className="client-logo  extra" src={client4} />
          <div className="client-separator  extra"></div>
          <img className="client-logo  extra" src={client5} />
          <div className="client-separator  extra"></div>
          <img className="client-logo  extra" src={client6} />
          <div className="client-separator  extra"></div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
